import React, { useState, useEffect } from "react";
import axios from "./custom-axios/axios";
import "./DropdownPollutant.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { withRouter } from "react-router-dom";

function DropdownArea(props) {
  const [selectedValue, setSelectedValue] = useState("");
  const [options, setOptions] = useState([]);
  const weatherProducts = "Weather products";

  const handleChange = (event) => {
    const selectedId = parseInt(event.target.value);
    const area = options.find((o) => o.id === selectedId);

    if (area) {
      setSelectedValue(area.id);
      props.changeDomain(area);
    } else if (event.target.value === "weatherProducts") {
      props.history.push("/fileManager");
    }
  };

  useEffect(() => {
    fetchAreas();
  }, []);

  async function fetchAreas() {
    try {
      const { data } = await axios.get("/simulation-areas");
      setOptions(data);
    } catch {
      setOptions(["Options failed to load."]);
    }
  }

  return (
    <div className="selectPollutantSwitch selectDomain">
      <label className="selectPollutantLabel" htmlFor="pollutant-select">
        Избери регион:{" "}
      </label>
      <select
        className="selectPollutant"
        id="pollutant-select"
        value={selectedValue}
        onChange={handleChange}
      >
        <>
          {options ? (
            options.map((area) => (
              <>
              <option
                className="selectPollutantOption"
                key={area.id}
                value={area.id}
              >
                {area.areaName}
              </option>
              <option value="weatherProducts">{weatherProducts}</option>
              </>
            ))
          ) : (
            <option></option>
          )}
        </>
      </select>
    </div>
  );
}

export default withRouter(DropdownArea);
