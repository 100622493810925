import React, { Component } from 'react';
import { FolderRounded, InsertDriveFileRounded, ArrowBack } from '@material-ui/icons';

const folderEndpoints = {
  Visibility: 'https://rks.nothas.io/images_kosovo/VISIBILITY/',
  Temperature: 'https://rks.nothas.io/images_kosovo/TEMP/',
  Rain: 'https://rks.nothas.io/images_kosovo/RAIN/',
  Gust: 'https://rks.nothas.io/images_kosovo/GUST/',
  Pressure: 'https://rks.nothas.io/images_kosovo/PRESSURE/',
  Wind: 'https://rks.nothas.io/images_kosovo/WIND/',
};

class FileExplorer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selectedFolder: null,
      selectedSubfolder: null,
      selectedFileIndex: null,
      selectedImageUrl: null,
      transitioning: false,
    };
    this.fileListRef = React.createRef();
  }

  componentDidMount() {
    const folderData = [
      { id: 1, type: 'folder', name: 'Visibility', children: [] },
      { id: 2, type: 'folder', name: 'Temperature', children: [] },
      { id: 3, type: 'folder', name: 'Rain', children: [] },
      { id: 4, type: 'folder', name: 'Gust', children: [] },
      { id: 5, type: 'folder', name: 'Pressure', children: [] },
      { id: 6, type: 'folder', name: 'Wind', children: [] },
    ];

    this.setState({ data: folderData });
  }

  fetchFiles = async (folderName, subfolderName = '') => {
    const endpoint = `${folderEndpoints[folderName]}${subfolderName}/`;
    try {
      const response = await fetch(endpoint);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const files = await response.json();
      return files;
    } catch (error) {
      console.error('Error fetching files:', error);
      return [];
    }
  };

  handleFolderClick = async (folder) => {
    if (this.state.selectedFolder && this.state.selectedFolder.name === folder.name) return;

    this.setState({ transitioning: true });

    setTimeout(async () => {
      const files = await this.fetchFiles(folder.name);
      const directories = files.filter(file => file.type === 'directory');
      this.setState({
        selectedFolder: { ...folder, children: directories },
        selectedSubfolder: null,
        selectedFileIndex: null,
        selectedImageUrl: null,
        transitioning: false,
      });
    }, 300); // Adjust timing as needed
  };

  handleSubfolderClick = async (subfolder) => {
    const { selectedFolder } = this.state;
    const files = await this.fetchFiles(selectedFolder.name, subfolder.name);
    const subfolderFiles = files.filter(file => file.type === 'file');
    this.setState({
      selectedSubfolder: { ...subfolder, children: subfolderFiles },
      selectedFileIndex: null,
      selectedImageUrl: null
    });
  };

  handleFileClick = (file, index) => {
    const { selectedFolder, selectedSubfolder } = this.state;
    const endpoint = `${folderEndpoints[selectedFolder.name]}${selectedSubfolder.name}/`;
    const imageUrl = `${endpoint}${file.name}`;
    this.setState({ selectedImageUrl: imageUrl, selectedFileIndex: index }, () => {
      this.scrollToFile(index);
    });
  };

  handleBackClick = () => {
    this.setState({ selectedSubfolder: null, selectedFileIndex: null, selectedImageUrl: null });
  };

  handleKeyDown = (event) => {
    const { selectedSubfolder, selectedFileIndex } = this.state;

    if (!selectedSubfolder || selectedFileIndex === null) return;

    const files = selectedSubfolder.children;
    const lastIndex = files.length - 1;

    switch (event.key) {
      case 'ArrowUp':
        if (selectedFileIndex > 0) {
          this.handleFileClick(files[selectedFileIndex - 1], selectedFileIndex - 1);
        }
        break;
      case 'ArrowDown':
        if (selectedFileIndex < lastIndex) {
          this.handleFileClick(files[selectedFileIndex + 1], selectedFileIndex + 1);
        }
        break;
      default:
        break;
    }
  };

  scrollToFile = (index) => {
    const element = this.fileListRef.current.children[index];
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  render() {
    const { data, selectedFolder, selectedSubfolder, selectedFileIndex, selectedImageUrl, transitioning } = this.state;

    return (
      <div style={{ display: "flex" }} onKeyDown={this.handleKeyDown} tabIndex="0">
        <div className="file-explorer">
          <style>
            {`
              body {
                overflow: hidden;
              }
              .file-explorer {
                display: flex;
                border: 1px solid #ccc;
                border-radius: 10px;
                width: 400px;
                height: 90vh;
                background-color: #f0f0f0;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
                margin: 20px;
                overflow: hidden;
                flex-direction: column;
              }
              .folder-list {
                flex: 1;
                padding: 10px;
                overflow-y: auto;
              }
              .file-list {
                flex: 1;
                padding: 10px;
                overflow-y: auto;
                position: relative;
                transition: opacity 0.3s ease, transform 0.3s ease;
                opacity: ${transitioning ? 0 : 1};
                transform: translateY(${transitioning ? '-10px' : '0'});
              }
              .folder {
                margin-bottom: 5px;
                display: flex;
                align-items: center;
                cursor: pointer;
              }
              .folder-name {
                cursor: pointer;
                position: relative;
                display: inline-flex;
                align-items: center;
                padding-left: 10px;
              }
              .file {
                align-items: center;
                cursor: pointer;
                padding: 5px;
                border-radius: 5px;
                transition: background-color 0.3s ease;
                font-weight: normal;
              }
              .file.selected {
                font-weight: bold;
                background-color: #e0e0e0;
              }
              .image-container {
                flex: 1;
                padding: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
              }
              .image-container img {
                max-width: 100%;
                max-height: 80%;
                object-fit: contain;
                border: 1px solid #ccc;
                border-radius: 10px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
              }
              .back-button {
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                padding: 10px;
                position: sticky;
                top: 0;
                background-color: #f0f0f0;
                z-index: 1;
                transition: opacity 0.3s ease, transform 0.3s ease;
                opacity: ${transitioning ? 0 : 1};
                transform: translateY(${transitioning ? '-10px' : '0'});
              }
            `}
          </style>
          <div className="folder-list">
            <h1 style={{ marginTop: "20px" }}>File Manager</h1>
            <div className="file-list" style={{ margin: "10px" }}>
              {data.map(item => (
                item.type === 'folder' ? (
                  <div key={item.id} className="folder">
                    <FolderRounded />
                    <div className="folder-name" onClick={() => this.handleFolderClick(item)} style={{ margin: "1rem" }}>
                      {item.name}
                    </div>
                  </div>
                ) : null
              ))}
            </div>
          </div>
        </div>
        {selectedFolder && !selectedSubfolder && (
          <div className="file-explorer">
            <div className="file-list" style={{ margin: "10px" }}>
              {selectedFolder.children.map((item) => (
                <div key={item.name} className="folder" onClick={() => this.handleSubfolderClick(item)}>
                  <FolderRounded />
                  <div className="folder-name" style={{ margin: "1rem" }}>
                    {item.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {selectedSubfolder && (
          <div className="file-explorer">
            <div className="back-button" onClick={this.handleBackClick}>
                <ArrowBack />
                <span style={{ marginLeft: "3px" }}>Back</span>
              </div>
            <div className="file-list" style={{ margin: "10px", overflowY: 'auto' }} ref={this.fileListRef}>
              {selectedSubfolder.children.map((item, index) => (
                <div key={item.name} id={`file-${index}`} className={`file ${index === selectedFileIndex ? 'selected' : ''}`} onClick={() => this.handleFileClick(item, index)}>
                  <InsertDriveFileRounded />
                  <span style={{ marginLeft: "3px", fontWeight: index === selectedFileIndex ? 'bold' : 'normal' }}>{item.name}</span>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="image-container">
          {selectedImageUrl && (
            <img src={selectedImageUrl} alt="Selected" />
          )}
        </div>
      </div>
    );
  }
}

export default FileExplorer;
